import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

import showMktoForm from "../../../helpers/showMktoForm";

const SpecializedIndustries = () => {
  const title = "Specialized Industries";
  const description =
    "Learn about our specialized industries at WaFd Bank including Government Banking, Association Banking, Agribusiness and more. Talk to a commercial banker today.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/specialized-industries/hero-specialized-industries-12302022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-government-banking-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-agribusiness-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card04: file(relativePath: { eq: "cards/thumbnail-senior-housing-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card05: file(relativePath: { eq: "cards/thumbnail-professional-service-firms-02072023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/specialized-industries"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-specialized-industries-12302022.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "specialized-industries-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Coworkers sitting at a conference table",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Here for whatever you need"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Specialized Industries"
    }
  ];

  const cardsData = {
    sectionClass: "bg-light",
    hasGrowEffect: true,
    cards: [
      {
        id: "specialized-industries-card-01",
        url: "/commercial-banking/specialized-industries/institutional-government-banking",
        title: "Government Banking",
        text: "Our Government Banking team focuses exclusively on the public sector.",
        image: {
          imgVariable: imgData.card01.childImageSharp.gatsbyImageData,
          altText: "Businesswoman standing in front of government building columns"
        }
      },
      {
        id: "specialized-industries-card-02",
        url: "/commercial-banking/specialized-industries/agribusiness-banking",
        title: "Agribusiness",
        text: "At WaFd Bank, our Agribusiness Team has practical knowledge and common sense to assist you with your agricultural financing needs.",
        image: {
          imgVariable: imgData.card03.childImageSharp.gatsbyImageData,
          altText: "Farmer standing in front of a tractor"
        }
      },
      {
        id: "specialized-industries-card-03",
        url: "/commercial-banking/specialized-industries/senior-housing",
        title: "Senior Housing",
        text: "WaFd Bank understands the full spectrum of the market and the need for both affordable and luxury retirement communities.",
        image: {
          imgVariable: imgData.card04.childImageSharp.gatsbyImageData,
          altText: "Senior housing building with tropical trees on a sunny day"
        }
      },
      {
        id: "specialized-industries-card-04",
        url: "/commercial-banking/specialized-industries/professional-service-firms",
        title: "Professional Service Firms",
        text: "WaFd Bank facilities can help your firm if you need financing.",
        image: {
          imgVariable: imgData.card05.childImageSharp.gatsbyImageData,
          altText: "Two business people walking and talking in an office building"
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Specialized Industries</h1>
        <h3>
          Our specialized Commercial Bankers understand your specific business needs. Start capitalizing on
          opportunities today with our flexible products, experienced bankers and trusted services built with your
          business goals in mind.
        </h3>
      </section>
      <StretchedLinkCards {...cardsData} />
      <ContactInfo type="commercial" />
    </SecondaryLanding>
  );
};
export default SpecializedIndustries;
